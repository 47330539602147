import React, { useState, useEffect } from "react";
import axios from "axios";
import Calendar from "react-calendar";
import "./Calendar.scss";
import dayjs from "dayjs";
import Loader from "./Loader";
import { get24Hrs } from "./Dialog";
import isEmail from "validator/es/lib/isEmail";
import { useAppData } from "../Context/AppContext";

import { PhoneInput as ReactPhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';


const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const TIME_API_URL = process.env.REACT_APP_API_URL || "https://run.salesblink.io/api/meet/slug";
const SLUG = window.location.href?.split("/")[window.location.href?.split("/").length - 1] || window.location.hostname;

function TimePicker({ selectedDate, onSelect, availableTimes, userDetails, timeZone, showTimeIn24Hr, setShowTimeIn24Hr }) {
    const [times, setTimes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const appData = useAppData();
    // const [showTimeIn24Hr, setShowTimeIn24Hr] = useState(false);

    async function fetchTimes(availableTimes) {
        try {
            let { data } = await axios.post(TIME_API_URL + `/${SLUG}/times`, { selectedDate, availableTimes, timeZone });
            setTimes(data.data);
            setIsLoading(false);
        } catch (e) {
            console.log("Error in fetching time from API ", e.response);
            setIsLoading(false);
            if(e?.response?.data?.title) appData?.setError(e?.response?.data?.title)
            if(e?.response?.data?.description) appData?.setErrorDescription(e?.response?.data?.description)
        }
    }

    function toggleShowTimeIn24Hr() {
        setShowTimeIn24Hr(!showTimeIn24Hr);
    }

    useEffect(() => {
        if (selectedDate) {
            setIsLoading(true);
            let day = dayjs(selectedDate).format("dddd");
            fetchTimes(availableTimes[day]);
        }
    }, [selectedDate, timeZone]);

    return (
        <div className="time-picker">
            {isLoading ? (
                <div style={{ margin: "auto" }}>
                    <Loader style={{ width: 40, height: 40, borderWidth: 4 }} />
                </div>
            ) : (
                <>
                    <div className="header">
                        <div className="date">
                            <div className="day">{DAYS[selectedDate.getDay()]}</div>
                            <div className="date-month">{`${selectedDate.getDate()} ${MONTHS[selectedDate.getMonth()]}`}</div>
                        </div>
                        <div className="switch" onClick={toggleShowTimeIn24Hr}>
                            <span className={`_12hr ${showTimeIn24Hr ? "" : "enabled"}`}>12H</span>
                            <span className={`_24hr ${showTimeIn24Hr ? "enabled" : ""}`}>24H</span>
                        </div>
                    </div>
                    <div className="slots">
                        {times.length === 0 && <h4 style={{ fontWeight: 500, textAlign: "center" }}>Oops, No Time Slots found!!</h4>}
                        {times.length > 0 &&
                            times.map((time) => (
                                <button onClick={() => onSelect(time)} className="slot" key={time}>
                                    {showTimeIn24Hr ? get24Hrs(time) : time}
                                </button>
                            ))}
                    </div>
                </>
            )}
        </div>
    );
}

function ScheduleForm({ customFields, onCancel, selectedTime, timeZone, date, setMeetingBooked }) {
    const [customQuestions, setCustomQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const appData = useAppData();

    const scheduleEvent = async () => {
        setNameError('');
        setEmailError('');
        setPhoneError('');

        for (const customQuestion of customQuestions) {
            if (customQuestion.required === "required" && customQuestion.answer === "") {
                setNameError('Name is required');
            }
            if (customQuestion.id === "i-2" && !isEmail(customQuestion.answer)) {
                setEmailError('Invalid email');
            }

            // check if number input is valid
            if (customQuestion.type === "number" && customQuestion.required === "required" && Number(customQuestion.answer) < 0) {
            }
            // check if phone number is valid
            // if (customQuestion.type === "phone_number" && customQuestion.required === "required") {
            //     try {
            //         const valid = phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(customQuestion.answer));
            //         if (!valid) return Toast.fire({ icon: "error", title: "Invalid Phone Number" });
            //     } catch (error) {
            //         return Toast.fire({ icon: "error", title: "Invalid Phone Number." });
            //     }
            // }
        }

        setLoading(true);
        try {
            // let time = `${moment(date).format("YYYY-MM-DD")}T${moment(selectedTime, "H:mm a").format("HH:mm:ss")}`;
            // time = new Date(time).getTime();
            /* New Logic with Dayjs */
            let newDate = dayjs(date).format("YYYY-MM-DD")
            let combinedDateTime = `${newDate} ${selectedTime}`;
            let parsedDateTime = dayjs(combinedDateTime, 'YYYY-MM-DD h:mm A');

            // Format parsed date and time to HH:mm:ss
            let time = parsedDateTime.format('HH:mm:ss');
            combinedDateTime = `${newDate}T${time}`
            time = new Date(combinedDateTime).getTime();

            let { data } = await axios.post(TIME_API_URL + `/${SLUG}/create`, {
                customQuestions,
                time,
                timeZone
            });

            setLoading(false);

            // iframe detection
            if (window.self !== window.top) {
                if (data.data === "https://salesblink.io/") return setMeetingBooked(true);
                window.top.location.href = data.data;
                return;
            }

            if (data.data === "https://salesblink.io/") return setMeetingBooked(true);
            window.location.href = data.data;
        } catch (e) {
            setLoading(false);
            console.log("Error in fetching time from API ", e.response);
            if(e?.response?.data?.title) appData?.setError(e?.response?.data?.title)
            if(e?.response?.data?.description) appData?.setErrorDescription(e?.response?.data?.description)
        }
    };
    const updateAnswer = (id, answer) => {
        setCustomQuestions((oldFields) => {
            let newFields = oldFields.map((field) => {
                if (field.id === id) {
                    field["answer"] = answer;
                    if (id === "i-1") {
                        setNameError(answer ? '' : 'Name is required');
                    } else if (id === "i-2") {
                        setEmailError(answer ? (isEmail(answer) ? '' : 'Invalid email') : '');
                    }
                    // else if (field.type === "phone_number") {
                    //     try {
                    //         const valid = phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(answer));
                    //         setPhoneError(answer ? (valid ? '' : 'Invalid Phone Number') : '');
                    //     } catch (error) {
                    //         setPhoneError(answer ? 'Invalid Phone Number.' : '');
                    //     }
                    // }
                }
                return field;
            });
            return newFields;
        });
    };


    useEffect(() => {
        if (customFields && customFields.length > 0) {
            let customQuestionArr = [];
            for (let i = 0; i < customFields.length; i++) {
                customQuestionArr.push({
                    id: customFields[i].id,
                    question: customFields[i].question,
                    required: customFields[i].required,
                    field: customFields[i].field,
                    answer: "",
                    type: customFields[i].type
                });
            }
            // console.log("QUES ",customQuestionArr)
            setCustomQuestions(customQuestionArr);
        }
    }, [customFields]);

    return (
        <div className="schedule-form">
            <form className="form">
                <h1 className="header">Enter Details</h1>
                {customQuestions.map((customQuestion) => (
                    <div key={customQuestion.id} className="input-container">
                        <label htmlFor={customQuestion.id} aria-required={customQuestion.required === "required"}>
                            {customQuestion.question}
                        </label>
                        {(function () {
                            switch (customQuestion.type) {
                                case "long_text":
                                    return (
                                        <textarea
                                            id={customQuestion.id}
                                            className=""
                                            name="name"
                                            value={customQuestion.answer}
                                            onChange={(e) => updateAnswer(customQuestion.id, e.target.value)}
                                            required={customQuestion.required === "required"}
                                        />
                                    );
                                case "phone_number":
                                    return (
                                        <ReactPhoneInput
                                            defaultCountry="us"
                                            value={customQuestion.answer}
                                            onChange={(number) => updateAnswer(customQuestion.id, number)}
                                            style={{ height: 50 }}
                                            countrySelectorStyleProps={{ buttonStyle: { marginTop: "unset", height: "40px" } }}
                                            inputStyle={{
                                                width: "286px",
                                                height: "40px",
                                                borderRadius: "0 5px 5px 0"
                                            }}
                                            disableFormatting={true}
                                            forceDialCode={true}
                                        />
                                    );

                                case "number":
                                    return (
                                        <input
                                            id={customQuestion.id}
                                            type="number"
                                            className=""
                                            name="name"
                                            value={customQuestion.answer}
                                            onChange={(e) => updateAnswer(customQuestion.id, e.target.value)}
                                            required={customQuestion.required === "required"}
                                        />
                                    );

                                default:
                                    return (
                                        <input
                                            id={customQuestion.id}
                                            className=""
                                            name="name"
                                            value={customQuestion.answer}
                                            onChange={(e) => updateAnswer(customQuestion.id, e.target.value)}
                                            required={customQuestion.required === "required"}
                                        />
                                    );
                            }
                        })()}
                        <p className="error-message">{customQuestion.id === 'i-1' ? nameError : (customQuestion.id === 'i-2' ? emailError : '')}</p>

                    </div>
                ))}
                <div className="buttons">
                    <button
                        type="button"
                        onClick={() => onCancel()}
                        style={{ cursor: loading ? "not-allowed" : "pointer" }}
                        className="button secondary"
                        disabled={loading}
                    >
                        Back
                    </button>
                    <button
                        type="button"
                        className="button primary"
                        style={{ cursor: loading ? "not-allowed" : "pointer" }}
                        onClick={() => scheduleEvent()}
                        disabled={loading}
                    >
                        Schedule
                    </button>
                </div>
            </form>
            <style>
                {`
                    .error-message {
                        color: red;
                        font-size: 14px;
                        margin-top: -8px;
                    }
                `}
            </style>
        </div>
    );
}

export default function Content({
    userDetails,
    markedDates,
    availableTimes,
    timezone,
    updateSelectedTimeAndDate,
    showTimeIn24Hr,
    setShowTimeIn24Hr,
    setMeetingBooked,
    checkIfDateSelected,
    darkModeEnabled
}) {
    const [date, setDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const timeZone = timezone;
    const smallScreen = window.matchMedia('(max-width: 900px)').matches;

    if (selectedTime) {
        return (
            <ScheduleForm
                customFields={userDetails.meeting_custom_questions}
                onCancel={() => {
                    setSelectedTime(null);
                    updateSelectedTimeAndDate({ time: null });
                }}
                selectedTime={selectedTime}
                timeZone={timeZone}
                date={date}
                setMeetingBooked={setMeetingBooked}
            />
        );
    }

    function isDateEnabled(dateTs) {
        let todayStr = dayjs(dateTs).format("DD-MM-YYYY");
        // If date in markedDates return true
        for (let i = 0; i < markedDates.length; i++) {
            // console.log("SMTHNG ", markedDates[i], todayStr)
            if (markedDates[i] === todayStr) {
                return false;
            }
        }
        return true;
    }

    function updateDate(date) {
        setDate(date);
        setSelectedDate(date);
        updateSelectedTimeAndDate({ date });
        checkIfDateSelected(true)

    }

    function gotoPreviousMonth() {
        const newDate = new Date(new Date(date.toString()).setMonth(date.getMonth() - 1));
        setDate(newDate);
    }
    function gotoNextMonth() {
        const newDate = new Date(new Date(date.toString()).setMonth(date.getMonth() + 1));
        setDate(newDate);
    }

    return (
        <div className="content">
            {(!smallScreen || !selectedDate) && (

                <div>
                    <div className="controls">
                        <div className="month-year">
                            <div className="month">{MONTHS[dayjs(date).month()]}</div>
                            <div className="year">{dayjs(date).year()}</div>
                        </div>
                        <div className="backward-forward">
                            {(function () {
                                const enabled = +markedDates[0]?.split("-")[1] < date.getMonth() + 1;

                                return (
                                    <button
                                        className={`backward ${enabled ? "enabled" : ""}`}
                                        onClick={() => {
                                            enabled && gotoPreviousMonth();
                                        }}
                                    >
                                        {
                                            darkModeEnabled ?
                                                <>
                                                    <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M13.75 16.25C13.6515 16.2505 13.5538 16.2313 13.4628 16.1935C13.3718 16.1557 13.2893 16.1001 13.22 16.03L9.72001 12.53C9.57956 12.3894 9.50067 12.1988 9.50067 12C9.50067 11.8013 9.57956 11.6107 9.72001 11.47L13.22 8.00003C13.361 7.90864 13.5285 7.86722 13.6958 7.88241C13.8631 7.89759 14.0205 7.96851 14.1427 8.08379C14.2649 8.19907 14.3448 8.35203 14.3697 8.51817C14.3946 8.68431 14.363 8.85399 14.28 9.00003L11.28 12L14.28 15C14.4205 15.1407 14.4994 15.3313 14.4994 15.53C14.4994 15.7288 14.4205 15.9194 14.28 16.06C14.1353 16.1907 13.9448 16.259 13.75 16.25Z" fill="#ffffff"></path> </g></svg>
                                                </>
                                                :
                                                <>
                                                    <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                        <g id="SVGRepo_iconCarrier">
                                                            <path d="M13.75 16.25C13.6515 16.2505 13.5538 16.2313 13.4628 16.1935C13.3718 16.1557 13.2893 16.1001 13.22 16.03L9.72001 12.53C9.57956 12.3894 9.50067 12.1988 9.50067 12C9.50067 11.8013 9.57956 11.6107 9.72001 11.47L13.22 8.00003C13.361 7.90864 13.5285 7.86722 13.6958 7.88241C13.8631 7.89759 14.0205 7.96851 14.1427 8.08379C14.2649 8.19907 14.3448 8.35203 14.3697 8.51817C14.3946 8.68431 14.363 8.85399 14.28 9.00003L11.28 12L14.28 15C14.4205 15.1407 14.4994 15.3313 14.4994 15.53C14.4994 15.7288 14.4205 15.9194 14.28 16.06C14.1353 16.1907 13.9448 16.259 13.75 16.25Z" fill="#000000"></path>
                                                        </g>
                                                    </svg>

                                                </>
                                        }
                                    </button>
                                );
                            })()}
                            <div className="seprator"></div>

                            {(function () {
                                const enabled = +markedDates[markedDates.length - 1]?.split("-")[1] > date.getMonth() + 1;

                                return (
                                    <button
                                        className={`forward ${enabled ? "enabled" : ""}`}
                                        onClick={() => {
                                            enabled && gotoNextMonth();
                                         }}
                                    >
                                        {
                                            darkModeEnabled ?
                                                <>
                                                    <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10.25 16.25C10.1493 16.2466 10.0503 16.2227 9.95921 16.1797C9.86807 16.1367 9.78668 16.0756 9.72001 16C9.57956 15.8594 9.50067 15.6688 9.50067 15.47C9.50067 15.2713 9.57956 15.0806 9.72001 14.94L12.72 11.94L9.72001 8.94002C9.66069 8.79601 9.64767 8.63711 9.68277 8.48536C9.71786 8.33361 9.79933 8.19656 9.91586 8.09322C10.0324 7.98988 10.1782 7.92538 10.3331 7.90868C10.4879 7.89198 10.6441 7.92391 10.78 8.00002L14.28 11.5C14.4205 11.6407 14.4994 11.8313 14.4994 12.03C14.4994 12.2288 14.4205 12.4194 14.28 12.56L10.78 16C10.7133 16.0756 10.6319 16.1367 10.5408 16.1797C10.4497 16.2227 10.3507 16.2466 10.25 16.25Z" fill="#ffffff"></path> </g></svg>
                                                </>
                                                :
                                                <>
                                                    <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                        <g id="SVGRepo_iconCarrier">
                                                            <path d="M10.25 16.25C10.1493 16.2466 10.0503 16.2227 9.95921 16.1797C9.86807 16.1367 9.78668 16.0756 9.72001 16C9.57956 15.8594 9.50067 15.6688 9.50067 15.47C9.50067 15.2713 9.57956 15.0806 9.72001 14.94L12.72 11.94L9.72001 8.94002C9.66069 8.79601 9.64767 8.63711 9.68277 8.48536C9.71786 8.33361 9.79933 8.19656 9.91586 8.09322C10.0324 7.98988 10.1782 7.92538 10.3331 7.90868C10.4879 7.89198 10.6441 7.92391 10.78 8.00002L14.28 11.5C14.4205 11.6407 14.4994 11.8313 14.4994 12.03C14.4994 12.2288 14.4205 12.4194 14.28 12.56L10.78 16C10.7133 16.0756 10.6319 16.1367 10.5408 16.1797C10.4497 16.2227 10.3507 16.2466 10.25 16.25Z" fill="#000000"></path>
                                                        </g>
                                                    </svg>

                                                </>
                                        }
                                    </button>
                                );
                            })()}
                        </div>
                    </div>
                    <Calendar
                        key={date.toString()}
                        tileDisabled={(today) => isDateEnabled(today.date.getTime())}
                        onChange={updateDate}
                        value={date}
                        tileClassName={({ date, view }) => {
                            const classes = [];
                            const pos = ["", "pos-one", "pos-two", "pos-three", "pos-four", "pos-five", "pos-six"];

                            // if it's the first day of the month then align it accordingly
                            if (date.getDate() === 1) {
                                classes.push("first-date");
                                classes.push(pos[date.getDay()]);
                            }

                            // these are the available dates
                            if (markedDates.find((x) => x === dayjs(date).format("DD-MM-YYYY"))) classes.push("hilighted_date");

                            // these dates are in the future
                            if (date.getMonth() > new Date().getMonth() || date.getDate() > new Date().getDate()) classes.push("future-date");

                            return classes.join(" ");
                        }}
                        showNavigation={false}
                        showNeighboringMonth={false}
                        calendarType="US"
                    />
                </div>
            )}

            {selectedDate && (
                <TimePicker
                    selectedDate={selectedDate}
                    onSelect={(selected) => {
                        setSelectedTime(selected);
                        updateSelectedTimeAndDate({ time: selected });
                    }}
                    availableTimes={availableTimes}
                    userDetails={userDetails}
                    timeZone={timeZone}
                    showTimeIn24Hr={showTimeIn24Hr}
                    setShowTimeIn24Hr={setShowTimeIn24Hr}
                />
            )}
        </div>
    );
}
