import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import Dialog from './Components/Dialog';
import { AppProvider } from './Context/AppContext';
// import { Head } from './Components/Partytown'; // @builder.io/partytown
// "partytown": "partytown copylib public/~partytown" // Needed in package.json { scripts }

function App() {
  return (
    <>
      {/* <Head /> */}
      <AppProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route path='/*' element={<>
                <Dialog />
              </>} />
            </Routes>
          </div>
        </Router>
      </AppProvider>
    </>
  );
}

export default App;
