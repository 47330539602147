import React, { useEffect, useState } from "react";
import "./TimezoneSelect.scss";
import timezones from "../timezones";
import axios from "axios";

const TIME_API_URL = process.env.REACT_APP_TIMEZONE_URL || "https://run.salesblink.io";

/*
 *
 * @param {autodetect: Boolean, onChange: function(newTimezone)} props
 * @returns
 */

export default function TimezoneSelect(props) {
    const [timezonesData, setTimezonesData] = useState([]);
    const [selected, setSelected] = useState("");
    const [expanded, setExpanded] = useState(false);
    const [query, setQuery] = useState("");
    let darkModeEnabled = props.darkModeEnabled

    useEffect(() => {
        fetchTimezones()
        setSelected(getCurrentUsersTimezone());
        props.onChange(getCurrentUsersTimezone());
    }, [])

    useEffect(() => {
        fetchTimezones()
    }, [query]);

    async function fetchTimezones() {
        try {
            let result = await axios.get(`${TIME_API_URL}/api/public/timezone?search=${query}`);
            const data = result?.data?.data;
            setTimezonesData(data);
        } catch (err) {

        }
    }

    function getCurrentUsersTimezone() {
        const country = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return country
    }

    return (
        <>
            {expanded && <div onClick={() => setExpanded(false)} className="selection-dismiss"></div>}
            <div>
                <div
                    onClick={() => {
                        if (props.disabled) return;

                        setExpanded(!expanded);
                        setQuery("");
                    }}
                    className="selection-button"
                    style={{ cursor: props.disabled ? "default" : "pointer" }}
                >
                    {
                         darkModeEnabled ?
                            <>
                                <svg width="30px" height="30px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 22.3201C17.5228 22.3201 22 17.8429 22 12.3201C22 6.79722 17.5228 2.32007 12 2.32007C6.47715 2.32007 2 6.79722 2 12.3201C2 17.8429 6.47715 22.3201 12 22.3201Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M2 12.3201H22" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 22.3201C13.933 22.3201 15.5 17.8429 15.5 12.3201C15.5 6.79722 13.933 2.32007 12 2.32007C10.067 2.32007 8.5 6.79722 8.5 12.3201C8.5 17.8429 10.067 22.3201 12 22.3201Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                            </>
                            :
                            <>
                                <svg width="30px" height="30px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path d="M12 22.3201C17.5228 22.3201 22 17.8429 22 12.3201C22 6.79722 17.5228 2.32007 12 2.32007C6.47715 2.32007 2 6.79722 2 12.3201C2 17.8429 6.47715 22.3201 12 22.3201Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M2 12.3201H22" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M12 22.3201C13.933 22.3201 15.5 17.8429 15.5 12.3201C15.5 6.79722 13.933 2.32007 12 2.32007C10.067 2.32007 8.5 6.79722 8.5 12.3201C8.5 17.8429 10.067 22.3201 12 22.3201Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </g>
                                </svg>

                            </>
                    }
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {String(selected).replace(/_/, " ")}
                        {!props.disabled &&
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 10L12 15L17 10" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        }
                    </div>
                </div>
                {expanded && (
                    <div className="selection-menu">
                        <input
                            onChange={(e) => setQuery(e.target.value)}
                            type="search"
                            className="selection-menu-search"
                            placeholder="Search Timezones"
                        />
                        {query.length > 0 ?
                            <div className="selection-menu-items">
                                {timezonesData?.map((timezone) => (
                                    <div
                                        key={timezone.label}
                                        onClick={() => {
                                            props.onChange(timezone.value);
                                            setSelected(timezone.value);
                                            setExpanded(false);
                                        }}
                                        className="selection-menu-item"
                                    >
                                        {timezone.label}
                                    </div>
                                ))}
                            </div>
                            : <></>}
                    </div>
                )}
            </div>
        </>
    );
}
