import React, { useState, useEffect } from "react";
import { useAppData } from "../Context/AppContext";
import Content from "./Content";
import Loader from "./Loader";
import dayjs from "dayjs";
import TimezoneSelect from "./TimezoneSelect";
import salesblinkLogo from "../assets/salesblink-icon-logo.png";
import MeetingBooked from "./MeetingBooked";

function DarkModeToggle({ showDown, darkModeEnabled, setDarkModeEnabled }) {
    function handleOnchange(event) {
        setDarkModeEnabled(event.target.checked);
    }

    return (
        <div className={showDown ? 'dark-mode-toggle-2' : 'dark-mode-toggle'}>
            {
                darkModeEnabled ?
                    <>
                        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 3V4M12 20V21M4 12H3M6.31412 6.31412L5.5 5.5M17.6859 6.31412L18.5 5.5M6.31412 17.69L5.5 18.5001M17.6859 17.69L18.5 18.5001M21 12H20M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </> :
                    <>
                        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M12 3V4M12 20V21M4 12H3M6.31412 6.31412L5.5 5.5M17.6859 6.31412L18.5 5.5M6.31412 17.69L5.5 18.5001M17.6859 17.69L18.5 18.5001M21 12H20M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </g>
                        </svg>

                    </>
            }
            <label className="switch">
                <input type="checkbox" checked={darkModeEnabled} onChange={handleOnchange} />
                <span className="slider round"></span>
            </label>
            {
                darkModeEnabled ?
                    <>
                        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3.32031 11.6835C3.32031 16.6541 7.34975 20.6835 12.3203 20.6835C16.1075 20.6835 19.3483 18.3443 20.6768 15.032C19.6402 15.4486 18.5059 15.6834 17.3203 15.6834C12.3497 15.6834 8.32031 11.654 8.32031 6.68342C8.32031 5.50338 8.55165 4.36259 8.96453 3.32996C5.65605 4.66028 3.32031 7.89912 3.32031 11.6835Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </> :
                    <>
                        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M3.32031 11.6835C3.32031 16.6541 7.34975 20.6835 12.3203 20.6835C16.1075 20.6835 19.3483 18.3443 20.6768 15.032C19.6402 15.4486 18.5059 15.6834 17.3203 15.6834C12.3497 15.6834 8.32031 11.654 8.32031 6.68342C8.32031 5.50338 8.55165 4.36259 8.96453 3.32996C5.65605 4.66028 3.32031 7.89912 3.32031 11.6835Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </g>
                        </svg>

                    </>
            }
        </div>
    );
}

function UserInfo({ userDetails, setTimeZone, setContextTimezone, selectedTimeAndDate, showTimeIn24Hr, isDateSelected, darkModeEnabled }) {
    const largeScreen = window.matchMedia('(min-width: 900px)').matches;

    return (
        <div className="userInfoSection">
            {(largeScreen || (!largeScreen && !isDateSelected)) && (
                <img
                    src={userDetails?.custom_profile_image || userDetails?.profile_image}
                    alt={userDetails?.custom_name || userDetails?.user.name}
                />
            )}            <h2>{userDetails?.custom_name || userDetails?.user.name}</h2>
            {/* <p>{userDetails?.companyName}</p> */}

            <div className="meeting-details">
                {userDetails?.description && (
                    <div className="meeting-details-heading">
                        <h3 className="meetingDetailsHeading">Meeting Details</h3>
                        <p>{userDetails?.description}</p>
                    </div>
                )}
                <div className="details">
                    {selectedTimeAndDate.date && selectedTimeAndDate.time && (
                        <p className="detail">
                            {`${dayjs(selectedTimeAndDate.date).format("DD MMMM YY")}, ${showTimeIn24Hr ? get24Hrs(selectedTimeAndDate.time) : selectedTimeAndDate.time
                                }`}
                        </p>
                     )} 
                    <p className="detail">
                        {
                            darkModeEnabled ?
                                <>
                                    <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 7V12L14.5 13.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                </>
                                :
                                <>
                                    <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path d="M12 7V12L14.5 13.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </g>
                                    </svg>

                                </>
                        }
                        {userDetails?.slots + " Minutes"}
                    </p>
                    <p className="detail">
                        {
                            darkModeEnabled ?
                                <>
                                    {userDetails?.location && (
                                        <svg width="20px" height="20px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#ffffff">
                                            <path class="c" d="m31.6814,34.8868c-1.9155,1.29-4.3586,2.0718-7.2514,2.0718-5.59,0-10.3395-3.7723-12.04-8.8541v-.0195c-.43-1.29-.6841-2.6582-.6841-4.085s.2541-2.795.6841-4.085c1.7005-5.0818,6.45-8.8541,12.04-8.8541,3.1664,0,5.9809,1.0945,8.2286,3.2055l6.1568-6.1568c-3.7332-3.4791-8.5805-5.6095-14.3855-5.6095-8.4045,0-15.6559,4.8277-19.1936,11.8641-1.4659,2.8927-2.3064,6.1568-2.3064,9.6359s.8405,6.7432,2.3064,9.6359v.0195c3.5377,7.0168,10.7891,11.8445,19.1936,11.8445,5.805,0,10.6718-1.9155,14.2291-5.1991,4.0655-3.7527,6.4109-9.2645,6.4109-15.8123,0-1.5245-.1368-2.9905-.3909-4.3977h-20.2491v8.3264h11.5709c-.5082,2.6777-2.0327,4.945-4.3195,6.4695h0Z"></path>
                                        </svg>)

                                    }
                                    {userDetails?.location}
                                </>
                                :
                                <>
                                    <>
                                        {userDetails?.location && (
                                           <svg width="20px" height="20px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                           <path class="c" d="m31.6814,34.8868c-1.9155,1.29-4.3586,2.0718-7.2514,2.0718-5.59,0-10.3395-3.7723-12.04-8.8541v-.0195c-.43-1.29-.6841-2.6582-.6841-4.085s.2541-2.795.6841-4.085c1.7005-5.0818,6.45-8.8541,12.04-8.8541,3.1664,0,5.9809,1.0945,8.2286,3.2055l6.1568-6.1568c-3.7332-3.4791-8.5805-5.6095-14.3855-5.6095-8.4045,0-15.6559,4.8277-19.1936,11.8641-1.4659,2.8927-2.3064,6.1568-2.3064,9.6359s.8405,6.7432,2.3064,9.6359v.0195c3.5377,7.0168,10.7891,11.8445,19.1936,11.8445,5.805,0,10.6718-1.9155,14.2291-5.1991,4.0655-3.7527,6.4109-9.2645,6.4109-15.8123,0-1.5245-.1368-2.9905-.3909-4.3977h-20.2491v8.3264h11.5709c-.5082,2.6777-2.0327,4.945-4.3195,6.4695h0Z"></path>
                                         </svg>
                                            )

                                        }
                                        {userDetails?.location}
                                    </>
                                </>
                        }
                    </p>
                    <TimezoneSelect
                        autodetect={true}
                        onChange={(value) => {
                            setTimeZone(value);
                            if (value) setContextTimezone(value);
                        }}
                        disabled={!!selectedTimeAndDate.date && !!selectedTimeAndDate.time}
                        darkModeEnabled={darkModeEnabled}
                    />
                </div>
            </div>
        </div>
    );
}

export default function Dialog() {
    const smallScreen = window.matchMedia('(max-width: 900px)').matches;
    let [isDateSelected, setIsDateSelected] = useState(false)

    const appData = useAppData();
    const [contextTimezone, setContextTimezone] = useState(null);
    const [timezone, setTimezone] = useState();
    const [darkModeEnabled, setDarkModeEnabled] = useState(window.matchMedia("(prefers-color-scheme: dark)").matches);
    const [selectedTimeAndDate, setSelectedTimeAndDate] = useState({
        date: null,
        time: null
    });
    const [showTimeIn24Hr, setShowTimeIn24Hr] = useState(false);
    const [meetingBooked, setMeetingBooked] = useState(false);
    const [meetingBookedData, setMeetingBookedData] = useState({
        dateTime: "",
        duration: 0,
        location: "",
        timezone: ""
    });
    function checkIfDateSelected(val) {
        setIsDateSelected(val)
    }
    function updateSelectedTimeAndDate({ time, date }) {
        // setting time and date explicitly to null
        if (time || time === null) setSelectedTimeAndDate((previousState) => ({ ...previousState, time }));
        else if (date || time === null) setSelectedTimeAndDate((previousState) => ({ ...previousState, date }));
    }

    // toggle dark mode
    useEffect(() => (darkModeEnabled ? document.body.classList.add("dark") : document.body.classList.remove("dark")), [darkModeEnabled]);

    useEffect(() => {
        // console.log('ZONE ', timezone)
        if (window.location.pathname === "/" && window.location.origin.includes("meet.salesblink.io")) {
            window.location.replace("https://www.salesblink.io/?utm_source=meeting_homepage&utm_medium=redirect&utm_campaign=meeting_homepage");
        }
        appData.fetchData(contextTimezone);
    }, []);

    // update meeting booked data to show at the end screen
    useEffect(() => {
        const date = dayjs(selectedTimeAndDate.date).format("DD MMMM YY");
        // const date = moment(selectedTimeAndDate.date).format("Do MMMM YY");
        const time = showTimeIn24Hr ? get24Hrs(selectedTimeAndDate.time) : selectedTimeAndDate.time;
        const duration = appData?.apiData?.slots;
        const location = appData?.apiData?.location;

        setMeetingBookedData({ duration, location, timezone, dateTime: `${date}, ${time}` });
    }, [selectedTimeAndDate, timezone, appData]);

    useEffect(() => {
        if (contextTimezone) appData.fetchData(contextTimezone);
    }, [contextTimezone]);

    if (!appData.apiData || appData.error) {
        return (
            <div className="dialog" style={{ flexDirection: 'column' }}>
                <div style={{ margin: "auto" }}>
                    <Loader style={{ width: 40, height: 40, borderWidth: 8 }} />
                </div>
                <div className="error" style={{ textAlign: 'center' }}>{appData.error}</div>
                {appData.errorDescription && <div className="error" style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: appData.errorDescription }}></div>}
            </div>
        );
    }
    const dialogStyle = {
        marginTop: smallScreen ? '15px' : '75px',
    };
    return (
        <>
            <div className="dialog" style={dialogStyle}>
                {meetingBooked ? (
                    <MeetingBooked meetingBookedData={meetingBookedData} darkModeEnabled={darkModeEnabled} />
                ) : (
                    <>
                        <UserInfo
                            userDetails={appData?.apiData}
                            setTimeZone={setTimezone}
                            setContextTimezone={setContextTimezone}
                            selectedTimeAndDate={selectedTimeAndDate}
                            showTimeIn24Hr={showTimeIn24Hr}
                            isDateSelected={isDateSelected}
                            darkModeEnabled={darkModeEnabled}

                        />
                        <Content
                            userDetails={appData?.apiData}
                            markedDates={appData?.dates}
                            availableTimes={appData?.availableTimes}
                            setContextTimezone={setContextTimezone}
                            timezone={timezone}
                            updateSelectedTimeAndDate={updateSelectedTimeAndDate}
                            showTimeIn24Hr={showTimeIn24Hr}
                            setShowTimeIn24Hr={setShowTimeIn24Hr}
                            setMeetingBooked={setMeetingBooked}
                            checkIfDateSelected={checkIfDateSelected}
                            darkModeEnabled={darkModeEnabled}
                        />
                    </>
                )}
                {
                    !smallScreen &&
                    <DarkModeToggle showDown={false} darkModeEnabled={darkModeEnabled} setDarkModeEnabled={setDarkModeEnabled} />
                }
                {
                    smallScreen &&
                    <DarkModeToggle showDown={true} darkModeEnabled={darkModeEnabled} setDarkModeEnabled={setDarkModeEnabled} />
                }
                {/* <DarkModeToggle darkModeEnabled={darkModeEnabled} setDarkModeEnabled={setDarkModeEnabled} /> */}
            </div>
            <div style={{ position: "relative", marginBottom: 20 }}>
                {!appData?.apiData?.whitelabelCalendar && <div className="branding">
                    <p>Powered By</p>
                    <img
                        src={salesblinkLogo}
                        onClick={(e) =>
                            window.location.replace(
                                "https://www.salesblink.io/?utm_source=meeting_link&utm_medium=redirect&utm_campaign=meeting_link"
                            )
                        }
                    />
                    <p
                        className="company-name"
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                            window.location.replace(
                                "https://www.salesblink.io/?utm_source=meeting_link&utm_medium=redirect&utm_campaign=meeting_link"
                            )
                        }
                    >
                        sales<span>blink</span>
                    </p>
                </div>}
            </div>
        </>
    );
}

export function get24Hrs(timeIn12Hrs) {
    const date = new Date(`1970-1-1 ${timeIn12Hrs}`);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${!hours ? "00" : hours}:${!minutes ? "00" : minutes}`;
}
