import React from 'react'
import "./Loader.scss";

export default function Loader({ style }) {
    return (
        <div className="loadingio-spinner-rolling-krvol4ua33b">
            <div className="ldio-krwj4tx5hr">
                 <div style={style}></div>
            </div>
        </div>
    )
}
